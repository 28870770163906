import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicLink, PrismicRichText } from '@prismicio/react'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import SliceList from '../components/sliceList'

export const query = graphql`
  query Products($skip: Int!, $limit: Int!) {
    prismicPage(uid: { eq: "inventory" }) {
      ...PrismicPageFragment
    }
    allPrismicProduct(limit: $limit, skip: $skip, sort: { fields: data___status, order: ASC }) {
      edges {
        node {
          ...PrismicProductFragment
        }
      }
    }
  }
`

const ProductTemplate = ({ data, pageContext }) => {
  const pageData = data.prismicPage.data
  const products = data.allPrismicProduct.edges

  let images = pageData.page_image?.gatsbyImageData
  if (pageData.page_image.gatsbyImageData) {
    images = withArtDirection(getImage(pageData.page_image.gatsbyImageData), [
      {
        media: '(max-width: 768px)',
        image: getImage(pageData.page_image.thumbnails.mobile.gatsbyImageData)
      }
    ])
  }

  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const pageLink = '/inventory'
  const prevPage = currentPage - 1 === 1 ? pageLink : pageLink + '/' + (currentPage - 1).toString()
  const nextPage = pageLink + '/' + (currentPage + 1).toString()
  return (
    <Layout>
      <div>
        <div className='relative overflow-hidden bg-gray-900 text-white'>
          <div className='mx-auto max-w-screen-xl flex-row md:flex'>
            <div className='hero relative z-10 bg-gray-900 p-8 px-5 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:py-28 xl:pb-32'>
              <svg
                className='absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 text-gray-900 lg:block'
                fill='currentColor'
                viewBox='0 0 100 100'
                preserveAspectRatio='none'
                aria-hidden='true'>
                <polygon points='50,0 100,0 50,100 0,100'></polygon>
              </svg>
              <div className='relative z-20 flex flex-col items-stretch justify-center gap-4 md:max-w-screen-md md:items-start'>
                <PrismicRichText field={pageData.page_title.richText} />
                <div className='prose prose-xl prose-invert'>
                  <PrismicRichText field={pageData.page_text.richText} />
                </div>
                <PrismicLink className='button' field={pageData.page_button_link}>
                  {pageData.page_button_text}
                </PrismicLink>
              </div>
            </div>
            {pageData.page_image.gatsbyImageData && (
              <div className='lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2'>
                <GatsbyImage className='h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full' image={images} alt={pageData.page_image.alt || ''} />
              </div>
            )}
          </div>
        </div>
        <div className='relative mx-auto max-w-7xl px-4 py-5 pb-4 sm:px-6 sm:pb-8 md:py-8'>
          <div className='relative'>
            <ul className='grid grid-cols-1 gap-5 md:grid-cols-3'>
              {products.map((product, index) => {
                let productData = product.node.data
                return (
                  <li
                    key={`product-${index}`}
                    className='component-productcard ease relative flex transform flex-col overflow-hidden border border-gray-50 bg-white p-2 shadow-lg transition duration-300 hover:scale-105 hover:shadow-lg'>
                    <Link className='flex flex-1 flex-col' to={product.node.url}>
                      <div className='relative flex-shrink-0'>
                        <GatsbyImage image={productData.page_image.gatsbyImageData} alt={productData.page_image.alt || ''} />
                      </div>
                      <div className='m-3 flex flex-1 flex-col bg-white'>
                        <div>
                          {productData.status ? (
                            <span className='inline-flex items-center rounded-md bg-pink-100 px-2.5 py-0.5 text-sm font-medium text-pink-800'>Sold</span>
                          ) : (
                            <span className='inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800'>Available</span>
                          )}
                        </div>
                        <div className='mb-6 space-y-4'>
                          <h3>{productData.page_title.text}</h3>
                          <div className='mt-3 mb-6 text-base text-gray-500'>
                            {productData.page_text.text?.split(' ').slice(0, 30).join(' ')}
                            {productData.page_text.text && <span>&hellip;</span>}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <nav className='mx-auto mt-10 text-center'>
            {!isFirst && (
              <Link className='relative mx-1 inline-flex items-center rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 hover:bg-gray-100' to={prevPage} rel='prev'>
                ← Prev
              </Link>
            )}
            {currentPage >= 3 && (
              <Link
                className='relative mx-1 inline-flex items-center rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                activeClassName='bg-gray-100'
                to={`${pageLink}`}>
                01
              </Link>
            )}
            {currentPage >= 3 && <li className='relative inline-flex items-center rounded-sm px-4 py-2 text-sm text-gray-700'>&hellip;</li>}
            {numPages > 1 &&
              Array.from({ length: numPages }, (_, i) => {
                if (i < currentPage + 1 && i > currentPage - 3) {
                  return (
                    <Link
                      className='relative mx-1 inline-flex items-center rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                      activeClassName='bg-gray-100'
                      to={`${pageLink}${i === 0 ? '' : '/' + (i + 1)}`}>
                      {i + 1 < 10 ? `0${i + 1}` : i + 1}
                    </Link>
                  )
                }
                return null
              })}
            {currentPage + 1 < numPages && <li className='relative inline-flex items-center rounded-sm px-4 py-2 text-sm text-gray-700'>&hellip;</li>}
            {currentPage + 1 < numPages && (
              <Link className='relative mx-1 inline-flex items-center rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 hover:bg-gray-100' to={`${pageLink}/${numPages}`}>
                {numPages < 10 ? `0${numPages}` : numPages}
              </Link>
            )}
            {!isLast && (
              <Link className='relative mx-1 inline-flex items-center rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 hover:bg-gray-100' to={nextPage} rel='next'>
                Next →
              </Link>
            )}
          </nav>
          <SliceList slices={pageData.body} />
        </div>
      </div>
    </Layout>
  )
}

export default withPrismicPreview(ProductTemplate)

export const Head = ({ data, location }) => {
  const pageData = data.prismicPage.data
  return <Seo pathname={location.pathname} title={pageData.meta_title ? pageData.meta_title : pageData.title} description={pageData.meta_description} image={pageData.page_image.url} />
}
